/**
 * This mixin is supposed to be used as a helper for `VDataTable` based
 * components or it's wrapper
 */

/**
 * converts current frontend (Vuetify) pagination object to object, which
 * properties are supported by common list requests
 *
 * @return {Object}         object, which properties are supported by
 *                          common list requests
 */
export function getPaginationForRequest (
  {
    page, rowsPerPage, sortBy, descending
  }) {
  return {
    page,
    size: rowsPerPage,
    orderByColumn: sortBy,
    sortOrder: descending ? 'desc' : 'asc'
  }
}

/**
 * check whether the maximal amount of result items, returned by API is
 * limited (the check is based on comparison of expected and real
 * result counts)
 *
 * @param resRowCount       amount of result items, returned by API
 * @param resTotalCount     total amount of results, returned by API
 * @param pagination        pagination state, used for API request
 */
export function calculateRowsPerPageLimit (
  resRowCount, resTotalCount, {rowsPerPage, page}) {
  let limit = rowsPerPage

  if (resRowCount > 0) {
    // rows per page is fixed (numeric)
    const isRowsPerPageNumeric = rowsPerPage > 0

    const gotLessThenRequested = rowsPerPage > resRowCount

    const isTotalCountWrong =
      resTotalCount !== (page - 1) * rowsPerPage + resRowCount

    const isTotalCountWrongForPagination =
      isRowsPerPageNumeric && gotLessThenRequested && isTotalCountWrong

    // requested all, but got less (or more %-/)
    const isTotalCountWrongWithoutPagination =
      !isRowsPerPageNumeric && resTotalCount !== resRowCount

    if (isTotalCountWrongForPagination || isTotalCountWrongWithoutPagination) {
      // store the limit
      limit = resRowCount
    }
  }

  return limit
}

export const DefaultRowsPerPage = 25
export const DefaultRowsPerPageItems = [10, DefaultRowsPerPage]

export default {
  props: {
    rowsPerPage: {
      type: Number,
      default: DefaultRowsPerPage
    },
    page: {
      type: Number,
      default: 1
    },
    sortBy: {
      type: String,
      default: undefined
    },
    descending: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      rowsPerPageLimit: -1
    }
  },

  computed: {
    /**
     * the pagination state, calculated from route parameters, (probably)
     * extended by bookEntry result list specific defaults
     */
    paginationState () {
      return {
        rowsPerPage: this.rowsPerPage,
        page: this.page,
        sortBy: (Array.isArray (this.sortBy) ? this.sortBy[0] : this.sortBy) || this.defaultSortBy,
        descending: this.descending
      }
    },

    /**
     * options for 'items-per-page-options' attribute of v-data-table component
     *
     * @return {*[]}      rows per page option array
     */
    rowsPerPageItems () {
      const maximum = this.rowsPerPageLimit <= 0
        ? {
          text: this.$t ('general.label.all'), value: -1
        }
        : this.rowsPerPageLimit

      return [...DefaultRowsPerPageItems, maximum]
    },

    /**
     * the default sort order when nothing is selected. should be
     * overwritten when used
     *
     * @return {String}      default sort column
     */
    defaultSortBy () {
      return undefined
    }
  }
}
