<template>
  <v-dialog
    v-model="show"
    :persistent="persistent"
    :scrollable="scrollable"
    :transition="transition"
    :overlay="overlay"
    :max-width="maxWidth"
    :fullscreen="isFullscreen"
    @keydown.esc="onEscPressed">
    <template #activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>

    <slot/>
  </v-dialog>
</template>

<script>
  export default {
    name: 'BaseDialog',

    props: {
      value: {
        // whether the dialog is shown
        type: Boolean,
        required: false,
        default: false
      },
      persistent: {
        // "persistent" property of <v-dialog>
        type: Boolean,
        default: false
      },
      scrollable: {
        // "scrollable" property of <v-dialog>
        type: Boolean,
        default: false
      },
      overlay: {
        // "overlay" property of <v-dialog>
        type: Boolean,
        default: true
      },
      fullscreen: {
        // "fullscreen" property of <v-dialog>
        type: Boolean,
        default: false
      },
      transition: {
        // "transition" property of <v-dialog>
        type: String,
        default: undefined
      },
      closeOnEsc: {
        // whether the dialog shall be closed when <Esc> is pressed
        type: Boolean,
        default: false
      },
      mw0: {
        // set maximum width to mw-0 (and set appropriate fullscreen breakpoint)
        type: Boolean,
        default: false
      },
      mw1: {
        // set maximum width to mw-1 (and set appropriate fullscreen breakpoint)
        type: Boolean,
        default: false
      },
      mw2: {
        // set maximum width to mw-2 (and set appropriate fullscreen breakpoint)
        type: Boolean,
        default: false
      },
      mw3: {
        // set maximum width to mw-3 (and set appropriate fullscreen breakpoint)
        type: Boolean,
        default: false
      },
      mw4: {
        // set maximum width to mw-4 (and set appropriate fullscreen breakpoint)
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        show: false
      }
    },

    computed: {
      /**
       * Get the "max-width" value for this component.
       *
       * The values returned by this method should be in line with the mw-X
       * classes from the custom styles definition (app/scss/custom.scss).
       *
       * @return {String}     the "max-width" value (100% if no mwX property is
       *                      set)
       */
      maxWidth () {
        if (this.mw0) return '750px'
        else if (this.mw1) return '1000px'
        else if (this.mw2) return '1250px'
        else if (this.mw3) return '1500px'
        else if (this.mw4) return '1750px'
        else return '100%'
      },

      /**
       * Whether the dialog shall be displayed in fullscreen mode.
       *
       * When the "fullscreen" property of the component is set to true,
       * fullscreen mode is enabled unconditionally. Otherwise, fullscreen mode
       * depends on the actual window size.
       *
       * @return {Boolean}      true if the dialog shall be displayed fullscreen
       */
      isFullscreen () {
        if (this.fullscreen) return true

        if (this.mw0) return this.$vuetify.breakpoint.xs
        else if (this.mw1) return this.$vuetify.breakpoint.smAndDown
        else if (this.mw2) return this.$vuetify.breakpoint.smAndDown
        else if (this.mw3) return this.$vuetify.breakpoint.smAndDown
        else if (this.mw4) return this.$vuetify.breakpoint.smAndDown
        else return this.$vuetify.breakpoint.smAndDown
      }
    },

    watch: {
      /**
       * Emit an input event when the internal dialog model changes.
       *
       * @param {Boolean} newValue      the newly set model value
       */
      show (newValue) {
        this.$emit ('input', newValue)
      },

      /**
       * Set the internal dialog model when the model of this component changes.
       *
       * @param {Boolean} newValue      the newly set model value
       */
      value (newValue) {
        this.show = newValue
      }
    },

    created () {
      this.show = this.value
    },

    methods: {
      /**
       * Emit an input event when <Esc> is pressed and the "close-on-esc"
       * property is set for this component.
       */
      onEscPressed () {
        if (this.closeOnEsc) {
          this.$emit ('input', false)
        }
      }
    }
  }
</script>
