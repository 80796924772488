<messages>["./FilterDateRangeItem"]</messages>

<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
          class="pa-1"
          cols="12" sm="4">
          <v-autocomplete
            v-model="rangeItem.timeRel"
            :items="timeRelations"/>
        </v-col>
        <v-col class="pa-1">
          <date-picker-field
            v-model="rangeItem.date"
            clearable
            :type="type"
            :label="$t ('label.date')"
            :error-messages="[]"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      align-self="center"
      class="pa-1"
      cols="auto">
      <filter-delete-button
        :show-delete="isDeleteButton"
        :disabled="isDisabled"
        @delete="$emit ('delete')"/>
    </v-col>
  </v-row>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep'
  import _isEmpty from 'lodash/isEmpty'
  import _isEqual from 'lodash/isEqual'

  import DatePickerField from '@/app/core/components/DatePickerField'
  import FilterDeleteButton from '@/app/core/components/Search/FilterDeleteButton'

  /** time relation to select single date */
  export const TIME_RELATION_EQUAL = 'on'
  export const TIME_RELATION_BEFORE = 'before'
  export const TIME_RELATION_AFTER = 'after'

  /** available time relations */
  export const timeRelations = [
    TIME_RELATION_EQUAL,
    TIME_RELATION_BEFORE,
    TIME_RELATION_AFTER
  ]

  /** available time relations for months */
  export const timeRelationsMonth = ['beforeMonth', 'afterMonth']

  /** create default date range item */
  export function defaultValue () {
    return {
      timeRel: null,
      date: null
    }
  }

  /**
   * check whether the given date range item is empty
   *
   * @param it {Object}   date range item to be checked
   * @return {Boolean}    {@code true} if the given date range item is empty,
   *                      {@code false} otherwise
   */
  export function isEmptyValue (it) {
    return !!(it === null || _isEmpty (it) || (!it.timeRel && !it.date))
  }

  /**
   * validate the given date range item
   *
   * @param it {Object}   date range item to be checked
   * @return {boolean}    {@code true} if the given date range item is valid,
   *                      {@code false} otherwise
   */
  export function validateValue (it) {
    return isEmptyValue (it) ||
      !!(timeRelations.includes (it.timeRel) && it.date)
  }

  export default {
    name: 'FilterDateRangeItem',

    components: {
      DatePickerField,
      FilterDeleteButton
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      deletable: {
        type: Boolean,
        dafault: false
      },
      type: {
        type: String,
        default: 'date'
      }
    },

    data () {
      return {
        rangeItem: defaultValue ()
      }
    },

    computed: {
      timeRelations () {
        return (this.type === 'date' ? timeRelations : timeRelationsMonth)
          .map (r => ({
            text: this.$t (`timeRel.${r}`),
            value: r
          }))
      },

      isDeleteButton () {
        return this.deletable && !this.isDisabled &&
          validateValue (this.rangeItem)
      },

      isDisabled () {
        return _isEmpty (this.rangeItem) ||
          (!this.rangeItem.timeRel && !this.rangeItem.date)
      }
    },

    watch: {
      value: {
        handler (newValue) {
          if (!_isEqual (newValue, this.rangeItem)) {
            this.populateRangeItem ()
          }
        },
        deep: true
      },

      rangeItem: {
        handler (newValue) {
          if (validateValue (newValue)) {
            this.$emit ('input', newValue)
          }
        },
        deep: true
      }
    },

    created () {
      this.populateRangeItem ()
    },

    methods: {
      populateRangeItem () {
        this.rangeItem = this.value ? _cloneDeep (this.value) : defaultValue ()
      }
    }
  }
</script>
