
<messages>["../BookEntry"]</messages>

<template>
  <v-menu offset-y open-on-hover>
    <template #activator="{on}">
      <span v-on="on">
        <slot/>
        <template v-if="!slotPassed && memorandum && label">
          {{ label }}
        </template>
        <v-icon v-if="memorandum" class="ml-2" small>info</v-icon>
      </span>
    </template>
    <v-card v-if="memorandum">
      <v-card-text class="text-center">
        <span>{{ memorandum }}</span>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

  import {EmptyMark} from '@/app/utils/string'

  export default {
    props: {
      label: {
        type: String,
        default: EmptyMark
      },
      memorandum: {
        type: String,
        default: undefined
      }
    },
    computed: {
      slotPassed () {
        return !!this.$slots.default
      }
    }
  }
</script>

<style scoped>
</style>
