export default {
  methods: {
    /**
     * Focus a element
     *
     * @param {String} ref reference to focus
     */
    focus (ref) {
      if (this.$refs[ref] && this.$refs[ref].focus) {
        this.$refs[ref].focus ()
      } else if (Array.isArray (this.$refs[ref]) &&
        this.$refs[ref][0] && this.$refs[ref][0].focus) {
        this.$refs[ref][0].focus ()
      }
    }
  }
}
