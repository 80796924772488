<messages>["@/app/i18n/common/GenericObject", "./BookEntry"]</messages>

<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '3' : '4'">
    <!-- filters -->
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <book-entry-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- bookEntry list -->
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>

      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <book-entry-table
            v-bind="paginationState"
            :book-entry-data="resultList"
            :loading="isLoading"
            :total-count="totalCount"
            :rows-per-page-items="rowsPerPageItems"
            @refund="onRefundClick"
            @chargeAgain="onChargeAgain"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"/>
          <invalid-warning v-if="showInvalidWarning"/>
        </v-card-text>
      </v-card>
    </v-col>
    <refund-dialog
      v-model="refundDialog"
      :is-loading="isLoadingRefund"
      :refund-item="refundItem"
      :cancel-action="cancelRefund"
      @ok="onRefund"/>
    <recharge-dialog
      v-model="rechargeDialog"
      :is-loading="isLoadingRecharge"
      :recharge-item="rechargeItem"
      :cancel-action="cancelRecharge"
      @ok="onRecharge"/>
  </base-layout>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import SearchPage from '@/app/core/mixins/SearchPage'

  import BookEntryFilter from './components/BookEntryFilter'
  import BookEntryTable from './components/BookEntryTable'
  import RefundDialog from './components/RefundDialog'
  import RechargeDialog from './components/RechargeDialog'
  import InvalidWarning from './components/InvalidWarning'

  import {invalidReferenceDate as referenceDate} from './constants'
  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'BookEntrySearch',

    components: {
      CsvDownloadBtn,
      BaseLayout,
      BookEntryFilter,
      BookEntryTable,
      RefundDialog,
      InvalidWarning,
      RechargeDialog
    },

    mixins: [SearchPage],

    // define standard sorting properties (override property definitions from
    // `SearchPage` mixin)
    props: {
      sortBy: {
        type: String,
        default: 'creationDate'
      },
      descending: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'account/list-bookentries',
        refundDialog: false,
        refundItem: null,
        isLoadingRefund: false,
        rechargeDialog: false,
        rechargeItem: null,
        isLoadingRecharge: false
      }
    },

    computed: {
      ...mapState ('filter', [
        'bookEntrySearchQuery'
      ]),

      showInvalidWarning () {
        return !!this.resultList?.find ((i) => i.creationDate < referenceDate)
      }
    },

    methods: {
      // --- mutation, mapped from vuex ----------------------------------------
      ...mapMutations ({
        storeSearchQuery: 'filter/setBookEntrySearchQuery'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.bookEntrySearchQuery
      },

      onRefundClick (item) {
        this.refundItem = item
        this.refundDialog = true
      },

      onChargeAgain (item) {
        this.rechargeItem = item
        this.rechargeDialog = true
      },

      onRecharge () {
        this.isLoadingRecharge = true

        this.fetchData ({
          op: 'account/recharge-bookentry',
          params: {
            id: this.rechargeItem.id
          },
          cb: () => {
            this.load ()
          },
          cbFinal: () => {
            this.isLoadingRecharge = false
            this.cancelRecharge ()
          }
        })
      },

      onRefund ({recreate, refundBundle}) {
        this.isLoadingRefund = true

        this.fetchData ({
          op: 'account/refund-bookentry',
          params: {
            id: this.refundItem.id,
            recreate,
            refundBundle
          },
          cb: () => {
            this.load ()
          },
          cbFinal: () => {
            this.isLoadingRefund = false
            this.cancelRefund ()
          }
        })
      },

      cancelRefund () {
        this.refundItem = null
        this.refundDialog = false
      },

      cancelRecharge () {
        this.rechargeItem = null
        this.rechargeDialog = false
      }
    }
  }
</script>
