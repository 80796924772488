<template>
  <div>
    <v-btn
      text
      :href="totalCount <= maxData ? csvDownloadUrl: undefined"
      @click="totalCount > maxData ? confirmationDialog = true : ''">
      {{ $t ('general.button.downloadAsCsv') }}
      <v-icon>get_app</v-icon>
    </v-btn>
    <confirmation-dialog v-model="confirmationDialog" :headline="$t ('general.search.largeCsv.header')">
      {{ $t ('general.search.largeCsv.info', {count: totalCount}) }}

      <template #actions>
        <v-btn
          v-t="'general.button.cancel'"
          text
          @click.native="confirmationDialog = false"/>
        <v-btn
          color="primary"
          :href="csvDownloadUrl"
          @click="confirmationDialog = false">
          {{ $t ('general.button.downloadAsCsv') }}
          <v-icon>get_app</v-icon>
        </v-btn>
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  const MAX_COUNT = 100000

  export default {
    name: 'CsvDownloadBtn',
    components: {ConfirmationDialog},
    props: {
      csvDownloadUrl: {
        type: String,
        required: true
      },
      totalCount: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        maxData: MAX_COUNT,
        confirmationDialog: false
      }
    }
  }
</script>

<style scoped>

</style>
