<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <span>
    <span
      v-for="item in activeItems"
      :key="item.label + item.text">
      <v-chip
        v-if="item.text"
        outlined
        close
        :disabled="disabled"
        class="ma-1"
        @click="onClicked (item.idx)"
        @update:active="onCloseFilter (item.idx)">
        <span
          class="chipContent"
          :title="item.text">
          <strong
            class="mr-1"
            v-text="item.label"/>
          <span v-text="item.text"/>
        </span>
      </v-chip>
    </span>
  </span>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  export default {
    name: 'QuickFilter',

    props: {
      // make produced chips non-closeable
      disabled: {
        type: Boolean,
        default: false
      },
      // filter items (array of pairs {label, text})
      items: {
        type: Array,
        default: undefined
      }
    },

    computed: {
      activeItems () {
        return this.items.reduce ((acc, it, idx) => {
          if (it.text) {
            acc.push ({
              ...it,
              idx
            })
          }

          return acc
        }, [])
      }
    },

    methods: {
      // --- event handlers ----------------------------------------------------

      /**
       * Handle a quick filter close event.
       *
       * @param {Number} index    the index of the filter that has been
       *                          deactivated
       */
      onCloseFilter (index) {
        this.$emit ('delete', index)
      },

      onClicked (index) {
        this.$emit ('clicked', index)
      }
    }
  }
</script>
