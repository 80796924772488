<template>
  <base-dialog
    v-model="showDialog"
    mw0
    persistent
    close-on-esc>
    <template #activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>

    <v-card :class="{'overflow-hidden': hideOverflow}">
      <v-card-title
        class="text-h5"
        v-text="headline"/>

      <v-card-text>
        <slot/>
      </v-card-text>

      <v-card-actions>
        <span
          v-if="errorMsg || $slots.error"
          class="red--text ml-3">
          <slot name="error"/>
          {{ errorMsg }}
        </span>
        <v-spacer/>
        <template v-if="!$slots.actions">
          <v-btn
            v-if="!disableCancel"
            v-t="'general.button.cancel'"
            text
            :disabled="isLoading"
            @click.native="cancelAction"/>
          <v-btn
            :color="!isDelete ? 'primary': 'error'"
            :loading="isLoading"
            @click.native="$emit('ok')">
            {{ customBtnText ? customBtnText : !isDelete ? $t ('general.button.ok') : $t ('general.button.delete') }}
          </v-btn>
        </template>
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'ConfirmationDialog',

    components: {
      BaseDialog
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
      headline: {
        type: String,
        required: true
      },
      hideOverflow: {
        type: Boolean,
        default: false
      },
      errorMsg: {
        type: String,
        default: ''
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      isDelete: {
        type: Boolean,
        default: false
      },
      customBtnText: {
        type: String,
        default: null
      },
      cancelAction: {
        type: Function,
        default () {
          this.showDialog = false
        }
      },
      disableCancel: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    }
  }
</script>
