<messages>["../BookEntry"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t('label.refund')"
    :is-loading="isLoading"
    :cancel-action="cancelRefund"
    @ok="ok">
    <v-row>
      <v-col cols="12">
        <i18n v-if="refundItem" :path="`label.refundConfirmation`">
          <template #amount>
            <span>{{ formatMoneyAmount (refundItem.amount, refundItem.currency) }}</span>
          </template>
          <template #client>
            <span>
              <client-link :id="refundItem.clientId"/>
              <template v-if="refundItem.clientId !== refundItem.subclientId">
                /
                <client-link :id="refundItem.subclientId"/>
              </template>
            </span>
          </template>
        </i18n>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="recreate"
          :label="$t ('label.refundRecreate')"/>
      </v-col>
      <template v-if="!bundleDisabled">
        <v-col cols="12">
          <v-checkbox
            v-model="refundBundle"
            :disabled="bundleDisabled"
            :label="$t ('label.refundBundle')"/>
        </v-col>
        <v-col v-show="refundBundle" cols="12">
          <bundle-list :book-entry-data="bundle" :loading="isLoadingBundle"/>
        </v-col>
      </template>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import ClientLink from '@/app/core/components/ClientLink'

  import BundleList from './BundleList'

  export default {

    components: {
      ConfirmationDialog,
      ClientLink,
      BundleList
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      refundItem: {
        type: Object,
        default: null
      },

      cancelRefund: {
        type: Function,
        default () {
          this.showDialog = false
        }
      }
    },

    data () {
      return {
        recreate: false,
        refundBundle: true,
        bundleDisabled: false,
        bundle: [],
        isLoadingBundle: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    watch: {
      showDialog (newVal) {
        if (!newVal) {
          this.refundBundle = true
          this.recreate = false
        } else {
          this.bundleDisabled = !this.refundItem.bundleId
          this.refundBundle = !this.bundleDisabled
          if (this.refundBundle) this.loadBundle ()
        }
      },
      'refundItem.bundleId' () {
        if (this.refundItem.bundleId) {
          this.loadBundle ()
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ok () {
        this.$emit ('ok', {
          recreate: this.recreate,
          refundBundle: this.refundBundle
        })
      },

      loadBundle () {
        this.isLoadingBundle = true

        this.fetchData ({
          op: 'account/list-bundled-bookentries',
          params: {
            id: this.refundItem.bundleId
          },
          cb: (data) => {
            this.bundle = data.list
          },
          cbFinal: () => {
            this.isLoadingBundle = false
          }
        })
      }
    }
  }
</script>
