<template>
  <div>
    <v-speed-dial
      v-if="buttons.length > 1"
      v-model="activated"
      direction="left">
      <template #activator>
        <v-btn
          v-model="activated"
          fab
          text
          x-small>
          <v-icon v-if="!activated">
            more_vert
          </v-icon>
          <v-icon v-else>
            close
          </v-icon>
        </v-btn>
      </template>
      <template
        v-for="button in buttons">
        <v-tooltip
          v-if="!button.divider"
          :key="button.icon"
          top>
          <template #activator="{on}">
            <div v-on="on">
              <v-btn
                fab
                x-small
                :color="button.color"
                :loading="button.isLoading"
                :disabled="button.disabled"
                :href="button.href ? button.href : undefined"
                :target="button.target ? button.target : undefined"

                @click="button.href ? undefined : buttonClicked (button)">
                <v-icon v-text="button.icon">
                  visibility
                </v-icon>
              </v-btn>
            </div>
          </template>
          {{ button.tooltip }}
        </v-tooltip>
        <v-divider v-else :key="button.icon" class="mx-2" vertical/>
      </template>
    </v-speed-dial>

    <v-tooltip
      v-else-if="buttons.length === 1"
      top>
      <template #activator="{ on }">
        <v-btn
          slot="activator"
          fab
          text
          x-small
          :color="buttonOne.color"
          :loading="buttonOne.isLoading"
          :disabled="buttonOne.disabled"
          :href="buttonOne.href ? buttonOne.href : undefined"
          :target="buttonOne.target ? buttonOne.target : undefined"
          v-on="on"
          @click="buttonOne.href ? undefined : buttonClicked (buttonOne)">
          <v-icon v-text="buttonOne.icon">
            visibility
          </v-icon>
        </v-btn>
      </template>
      {{ buttonOne.tooltip }}
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'ActionButtons',

    props: {
      buttons: {
        type: Array,
        default: () => []
      },
      value: Boolean
    },

    data () {
      return {
      }
    },

    computed: {
      buttonOne () {
        return this.buttons[0]
      },

      activated: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    watch: {
      value (newValue) {
        this.activated = newValue
      },

      activated (newValue) {
        this.$emit ('input', newValue)
      }
    },

    methods: {
      buttonClicked (button) {
        this.$emit ('clicked', button)
      }
    }
  }
</script>

<style scoped>
  /* WORKAROUND for transparent disabled action buttons (GWNG-236) */
  .theme--light .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: #e0e0e0 !important;
  }
</style>
