<template>
  <div class="px-2">
    <div class="v-label mx-n2" v-text="label"/>
    <filter-date-range-item
      v-for="(item, idx) in rangeItems"
      :key="JSON.stringify(item)"
      v-model="rangeItems[idx]"
      :type="type"
      :deletable="isItemDeletable (idx)"
      @delete="onDeleteDate (idx)"/>
  </div>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep'
  import _isEmpty from 'lodash/isEmpty'
  import _isEqual from 'lodash/isEqual'

  import FilterDateRangeItem, {
    TIME_RELATION_EQUAL,
    TIME_RELATION_BEFORE,
    TIME_RELATION_AFTER,
    defaultValue as defaultItemValue,
    isEmptyValue as isEmptyItemValue,
    validateValue as validateItem
  } from '@/app/core/components/Search/FilterDateRangeItem'

  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'

  export function validateValue (val) {
    let isValid = false

    if (val === null || _isEmpty (val)) {
      isValid = true
    } else {
      if (Array.isArray (val)) {
        isValid = true
        for (const it of val) {
          if (!validateItem (it)) {
            isValid = false
            break
          }
        }
        if (isValid) {
          if (val.length > 1) {
            if (val.length > 2) {
              isValid = false
            } else {
              if (
                (
                  val[0].timeRel === TIME_RELATION_EQUAL &&
                  !isEmptyItemValue (val[1])
                ) ||
                (
                  val[1].timeRel === TIME_RELATION_EQUAL &&
                  !isEmptyItemValue (val[0]))
              ) {
                isValid = false
              }
            }
          }
        }
      }
    }

    return isValid
  }

  function getDate (rel) {
    const date = rel ? new Date () : new Date ()
    date.setUTCHours (0, 0, 0, 0)
    return date
  }

  export function lastMonthItems () {
    const lastMonthStart = getDate ()
    lastMonthStart.setUTCDate (1)
    lastMonthStart.setUTCMonth (lastMonthStart.getUTCMonth () - 1)

    const currentMonthStart = getDate ()

    currentMonthStart.setUTCDate (2)

    return [
      {
        timeRel: TIME_RELATION_AFTER,
        date: formatDateShort (lastMonthStart, true)
      },
      {
        timeRel: TIME_RELATION_BEFORE,
        date: formatDateShort (currentMonthStart, true)
      }
    ]
  }

  export function lastYearItems () {
    const lastYearStart = getDate ()
    lastYearStart.setUTCDate (1)
    lastYearStart.setUTCMonth (0)
    lastYearStart.setUTCFullYear (lastYearStart.getUTCFullYear () - 1)

    const currentYearStart = getDate ()

    currentYearStart.setUTCDate (2)
    currentYearStart.setUTCMonth (0)

    return [
      {
        timeRel: TIME_RELATION_AFTER,
        date: formatDateShort (lastYearStart, true)
      },
      {
        timeRel: TIME_RELATION_BEFORE,
        date: formatDateShort (currentYearStart, true)
      }
    ]
  }

  export function currentMonthItems () {
    const currentMonthStart = getDate ()

    currentMonthStart.setUTCDate (1)

    return [
      {
        timeRel: TIME_RELATION_AFTER,
        date: formatDateShort (currentMonthStart, true)
      }
    ]
  }

  export function currentYearItems () {
    const currentYearStart = getDate ()

    currentYearStart.setUTCDate (1)
    currentYearStart.setUTCMonth (0)

    return [
      {
        timeRel: TIME_RELATION_AFTER,
        date: formatDateShort (currentYearStart, true)
      }
    ]
  }

  /** create default date range */
  export function defaultValue () {
    return [defaultItemValue ()]
  }

  export default {
    name: 'FilterDateRange',

    components: {
      FilterDateRangeItem
    },

    props: {
      value: {
        type: Array,
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      type: {
        type: String,
        default: 'date'
      }
    },

    data () {
      return {
        rangeItems: defaultValue ()
      }
    },

    watch: {
      value: {
        handler (newValue) {
          if (!_isEqual (newValue, this.rangeItems)) {
            this.populateRangeItems ()
          }
        },
        deep: true
      },

      rangeItems: {
        handler (newValue) {
          if (validateValue (newValue)) {
            this.$emit ('input', newValue)

            if (newValue.length === 1 &&
              newValue[0].timeRel &&
              newValue[0].timeRel !== TIME_RELATION_EQUAL) {
              this.rangeItems.push (defaultItemValue ())
            } else {
              if ((newValue.length === 2) &&
                newValue[0].timeRel &&
                (newValue[0].timeRel === TIME_RELATION_EQUAL) &&
                isEmptyItemValue (newValue[1])) {
                this.rangeItems.splice (1, 1)
              }
            }
          }
        },
        deep: true
      }
    },

    created () {
      this.populateRangeItems ()
    },

    methods: {
      isItemDeletable (idx) {
        return this.rangeItems.length > 1 &&
          (idx === 0 || validateItem (this.rangeItems[1]))
      },

      populateRangeItems () {
        this.rangeItems = this.value ? _cloneDeep (this.value) : defaultValue ()
      },

      onDeleteDate (idx) {
        if (this.rangeItems.length > 1) {
          this.rangeItems.splice (idx, 1)
        } else {
          this.$set (this.rangeItems, idx, defaultItemValue ())
        }
      }
    }
  }
</script>
