<template>
  <div
    class="text-center">
    <i18n
      v-if="isLoading"
      path="general.info.searchInProgress">
      <template #link>
        <v-btn
          v-show="isBtnVisible"
          v-t="'general.button.cancel'"
          small
          @click="cancelSearch"/>
      </template>
    </i18n>
    <span
      v-else
      v-text="text || $t ('general.pagination.noResultsText')"/>
  </div>
</template>

<script>
  const SEARCH_CANCEL_ACTIVATION_DELAY = 4 * 1000

  export default {
    name: 'SearchTableNoData',

    props: {
      isLoading: Boolean,
      text: {type: String, default: undefined}
    },

    data () {
      return {
        isBtnVisible: false
      }
    },

    created () {
      window.setTimeout (this.showBtn, SEARCH_CANCEL_ACTIVATION_DELAY)
    },

    methods: {
      cancelSearch () {
        const wl = window.location
        const l = wl.href.split ('?')

        if (l.length === 2) {
          wl.href = l[0]
          wl.reload ()
        }
      },

      showBtn () {
        this.isBtnVisible = true
      }
    }
  }
</script>
