
/**
 * A mixing that tries to unify the quick filter operations
 *
 * This mixin populated the fields:
 *  - additionalQuickFilterItems
 *    Array of objects from the currently entered additional filter params
 *  - quickFilterItems
 *    Array of objects from the currentyl active filter params
 * These field cannot be modified direcly
 * These fields are populated as follows:
 *  - each key of the currently active/entered filter will be mapped to an
 *    object that contains the following poperties:
 *    - text:
 *      the text that sould be dispayed for the quick filter.
 *      the i18n translation are taken form 'filter.${value}' with value beeing
 *      the value from the key (e.g. filterparams[key])
 *      if no translation is found the value from this key will be used as text
 *    - prop:
 *      the key itself
 *      this is used for deleting a filter
 *    - label:
 *      the label used for this quick filter
 *      the i18n translation are taken form 'filter.${key}'
 *      if no translation is found the key will be used as the label
 *
 * implemented special key:
 *  - dates:
 *    this property must be an array with the following objectstructur as items:
 *    - action:
 *      the action
 *    - timeRel:
 *      the time relation
 *    - date:
 *      the date
 *    each item of this array will be a quick filter
 *    with the following addional props:
 *    - idx:
 *      the index in the array
 *    - label:
 *      this will be `${this.$t (`filter.date.action.${action}`)} ${this.$t (`filter.date.timeRel.${timeRel}`)}`
 *    - text:
 *      this will be the date itself
 *  - registryTypes (registryTypeNames):
 *    this property must be an array of strings with the registry ids
 *    each item of this array will be a quick filter with the following addional
 *    props:
 *    - idx:
 *      index in the array
 *    - text:
 *      will be this.registryTypeData[value].label of the value itself
 *  - clientId:
 *    this property must be a number
 *    - text:
 *      will fetch the name of this clientid and present `${name} (${clientid})`
 *
 * data fields to implement:
 *  - additionalFilterKeys:
 *    key of the additional filter fields
 *  - emptyFilter:
 *    the default search filter
 *  - filterParams:
 *    the currently entered filter
 *    must be able to be modified
 *  - appliedFilter:
 *    the currently applied filter
 *  - customHandlerKeys (if a custom handler should be used for a key):
 *    array of key where a custom handler should be used
 *
 * methods to implement:
 *  - doSearch:
 *    do a search with the currently entered filterParams
 *  - customQuickFilterHandler (if a custom handler should be used for a key)
 *    @param key       the key for the custom handler
 *    @param value     the value of that key
 *    @param qFItems   the quick filter items where the quick filter should be
 *                     added
 *    add the quick filter to the array
 *
 */

import _isEqual from 'lodash/isEqual'

import {mapActions} from 'vuex'

export default {
  data () {
    return {
      additionalFilterKeys: [],
      customHandlerKeys: [],
      clientIdMap: {},
      registryTypeData: {},
      arrayProps: ['dates', 'registryTypes', 'registryTypeNames']
    }
  },

  watch: {
    async 'filterParams.clientId' (newVal) {
      this.$set (this.clientIdMap, newVal, await this.getClientName (newVal))
    },

    async 'appliedFilter.clientId' (newVal) {
      this.$set (this.clientIdMap, newVal, await this.getClientName (newVal))
    }
  },

  computed: {
    additionalQuickFilterItems () {
      return this.getQuickFilterItems (
        this.filterParams, this.additionalFilterKeys)
    },

    quickFilterItems () {
      const keys = Object.keys (this.appliedFilter).filter ((key) => key !== 'doSearch')
      return this.getQuickFilterItems (this.appliedFilter, keys)
    }
  },

  created () {
    (async () => {
      this.registryTypeData = await this.getRegistryTypeData ()
      this.clientIdMap[this.filterParams.clientId] =
        await this.getClientName (this.filterParams.clientId)
    }) ()
  },

  methods: {
    ...mapActions ({
      getClientName: 'cache/getClientName',
      getRegistryTypeData: 'cache/getRegistryTypeData'
    }),

    format (val, key, idx) {
      const clientNameMap = this.clientIdMap
      const regTypeNames = this.registryTypeData

      const registryTypeName = it =>
        (regTypeNames[it] && regTypeNames[it].label) || it

      const clientName = it =>
        (clientNameMap[it] || it)

      if (key === 'dates') {
        if (!val.action || !val.timeRel || !val.date) {
          return false
        }

        const action = this.$t (
          `filter.date.action.${val.action}`)

        const timeRel =
          this.$t (`filter.date.timeRel.${val.timeRel}`)

        return {
          label: `${action} ${timeRel}`,
          text: val.date,
          prop: key,
          idx
        }
      } else if (key === 'registryTypes' || key === 'registryTypeNames') {
        return {
          label: this.getLabel (key),
          text: registryTypeName (val),
          prop: key,
          idx
        }
      } else if (key === 'clientId') {
        const text = clientName (val)
        return {
          label: this.getLabel (key),
          text: text ? `${text} (${val})` : val,
          prop: key
        }
      } else {
        return {
          prop: key,
          label: this.getLabel (key),
          text: this.getLabel (val)
        }
      }
    },

    getLabel (val) {
      return val && this.$t (`filter.${val}`) !== `filter.${val}`
        ? this.$t (`filter.${val}`)
        : val
    },

    getQuickFilterItems (filter, filterKeys) {
      const qFItems = []

      filterKeys.forEach ((key) => {
        if (!_isEqual (filter[key], this.emptyFilter[key])) {
          if (this.customHandlerKeys.includes (key)) {
            this.customQuickFilterHandler (key, filter[key], qFItems)
          } else if (this.arrayProps.includes (key)) {
            filter[key].forEach ((val, idx) => {
              const addObject = this.format (val, key, idx)

              if (addObject) {
                qFItems.push (addObject)
              }
            })
          } else {
            const addObject = this.format (filter[key], key)

            if (addObject) {
              qFItems.push (addObject)
            }
          }
        }
      })

      return qFItems
    },

    onDeleteAdditionalFilter (index) {
      const {prop, idx} = this.additionalQuickFilterItems[index]

      if (idx !== undefined) {
        this.filterParams[prop].splice (idx, 1)
      } else {
        this.filterParams[prop] = this.emptyFilter[prop]
      }
    },

    onDeleteFilter (index) {
      const {prop, idx} = this.quickFilterItems[index]

      if (idx !== undefined) {
        this.filterParams[prop].splice (idx, 1)
      } else {
        this.filterParams[prop] = this.emptyFilter[prop]
      }

      this.doSearch ()
    },

    customQuickFilterHandler (key, value, qFItems) {
      qFItems.push ({
        prop: key,
        label: this.getLabel (key),
        text: this.getLabel (value)
      })
    }
  }
}
