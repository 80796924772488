
<messages>["../BookEntry"]</messages>

<template>
  <v-menu open-on-hover>
    <template #activator="{on}">
      <span v-on="on">
        {{ vatStrings }}
        <v-icon v-if="!hideInfo" small>info</v-icon>
      </span>
    </template>
    <v-card>
      <v-card-text>
        <table>
          <tr>
            <th v-t="'label.name'"/>
            <th v-t="'label.rate'"/>
            <th v-t="'label.amount'"/>
          </tr>
          <tr v-for="tax in vats" :key="tax.name">
            <td>{{ tax.name }}</td>
            <td>{{ tax.rate }}%</td>
            <td class="text-right">
              {{ formatMoneyAmount (tax.amount, currency) }}
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    props: {
      vats: {
        type: Array,
        default: null
      },
      currency: {
        type: String,
        default: 'USD'
      },
      hideInfo: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      vatStrings () {
        return this.vats
          ? this.vats.reduce ((acc, i) => {
            return (acc === '' ? '' : acc + ', ') + `${i.name} (${i.rate}%)`
          }, '')
          : ''
      }
    }
  }
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 2em 0;
}
</style>
