<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-menu
    v-model="isMenuVisible"
    :disabled="disabled"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="290px">
    <template #activator="{ on }">
      <v-text-field
        :value="format (value)"
        :disabled="disabled"
        :class="{required}"
        :label="label"
        :error-messages="errorMessages"
        append-icon="event"
        :clearable="clearable"
        readonly
        @click:append="on.click"
        v-on="on"
        @input="onInputText"
        @blur="$emit('blur')"/>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      actions
      first-day-of-week="1"
      :locale="$i18n.locale"
      :type="type"
      :allowed-dates="allowedDates"
      @input="onOk">
      <v-spacer/>
      <v-btn
        v-t="'general.button.cancel'"
        text
        @click="onCancel"/>
    </v-date-picker>
  </v-menu>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'

  export const future = date => new Date (date) > new Date ()

  /**
   * create the function, which checks whether the given date is in the past
   *
   * @param inclusiveToday            accept today's date as past?
   * @return {function(*=): boolean}  function, which checks whether the given
   *                                  date is in the past
   */
  export const past = (inclusiveToday = true) => dateStr => {
    const date = new Date (dateStr)
    const now = new Date (formatDateShort (new Date (), true))

    return inclusiveToday ? date <= now : date < now
  }

  /**
   * check whether the given date value is before beginning of current month
   *
   * @param value           value to check
   * @return {boolean}      {@code true} if the given value is falsy or the
   *                        date, represented by the value is before the 1-st
   *                        of current month, {@code false} otherwise
   */
  export const beforeCurrentMonth = value => {
    let isBefore = true

    if (value) {
      const d = new Date (value)
      const now = new Date ()
      const monthBegin = new Date (now.getUTCFullYear (), now.getUTCMonth (), 1)

      isBefore = monthBegin > d
    }

    return isBefore
  }

  export default {
    name: 'DatePickerField',

    props: {
      label: {
        type: String,
        default: undefined
      },
      required: Boolean,
      errorMessages: {
        type: Array,
        default: undefined
      },
      disabled: Boolean,
      allowedDates: {
        type: Function,
        default: undefined
      },
      clearable: Boolean,
      asUtc: Boolean,
      type: {
        type: String,
        default: undefined
      },
      value: {
        type: [String, Number],
        default: undefined
      }
    },

    data () {
      return {
        isMenuVisible: false,
        date: '',
        originalDate: ''
      }
    },

    computed: {
      format () {
        return (input) => this.type === 'month'
          ? this.formatDateMonth (input, true)
          : this.formatDateShort (input, true)
      }
    },

    watch: {
      value () {
        this.init ()
      }
    },

    created () {
      this.init ()
    },

    methods: {
      onCancel () {
        this.date = this.originalDate
        this.isMenuVisible = false
      },

      onOk () {
        this.update ()
        this.isMenuVisible = false
      },

      init () {
        this.date = this.asUtc ? this.format (this.value) : this.value
        this.originalDate = this.asUtc ? this.format (this.value) : this.value
      },

      update () {
        let date
        if (this.asUtc) {
          const d = new Date (this.date)
          date = null

          if (d instanceof Date && !isNaN (d)) { date = d.getTime () }
        } else {
          date = this.format (this.date)
        }

        this.$emit ('input', date)
      },

      onInputText (e) {
        if (e && e !== '') {
          this.update ()
        } else {
          this.date = null
          this.update ()
        }
      }
    }
  }
</script>
