import {mapActions} from 'vuex'

export default {
  data () {
    return {
      registryCommands: {},
      virtualMap: {}
    }
  },

  methods: {
    ...mapActions ({
      getRegistryTypeData: 'cache/getRegistryTypeData'
    }),

    /**
     * Whether the given registry supports the given command.
     *
     * @param {String} id           the registry to check, e.g., 'afilias'
     * @param {String} command      the command to check, e.g., 'DomainCreate'
     * @return {Boolean}            true if and only if the registry supports
     *                              the command
     */
    supports (id, command) {
      const registryCommands = this.registryCommands[id]
      return registryCommands?.includes (command)
    },

    isVirtual (id) {
      return this.virtualMap[id]
    },

    /**
     * Load the supported registry commands from the cache into the internal
     * map structure.
     */
    async loadRegistryCommands () {
      const data = await this.getRegistryTypeData ()
      for (const registry in data) {
        if (data.hasOwnProperty (registry)) {
          this.$set (this.registryCommands, registry, data[registry].commands)
          this.$set (this.virtualMap, registry, data[registry].virtual)
        }
      }
    }
  },

  created () {
    this.loadRegistryCommands ()
  }
}
