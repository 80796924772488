<template>
  <router-link
    v-if="mayViewClient (id)"
    :to="{name: 'client.view', params: {id}}"
    v-text="short ? shortName : clientName || id"/>
  <span v-else>
    {{ short ? shortName : clientName || id }}
  </span>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'ClientLink',

    props: {
      id: {
        type: Number,
        required: true
      },
      short: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        clientName: ''
      }
    },

    computed: {
      ...mapGetters ({
        mayViewClient: 'auth/mayViewClient'
      }),

      shortName () {
        return `CORE-${this.id}`
      }
    },

    watch: {
      id (newValue) {
        this.populateClientName (newValue)
      }
    },

    created () {
      this.populateClientName (this.id)
    },

    methods: {
      ...mapActions ({
        getClientNameExt: 'cache/getClientNameExt'
      }),

      async populateClientName (id) {
        this.clientName = await this.getClientNameExt (id)
      }
    }
  }
</script>
