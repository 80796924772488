<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        top
        small
        icon
        class="mx-0"
        :disabled="!showDelete && disabled"
        v-on="on"
        @click="$emit ('delete')">
        <v-icon v-if="showDelete">
          delete
        </v-icon>
        <v-icon v-else>
          undo
        </v-icon>
      </v-btn>
    </template>
    <span
      v-t="showDelete
        ? 'general.button.deleteFilter'
        : 'general.button.resetFilter'"/>
  </v-tooltip>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  export default {
    name: 'FilterDeleteButton',

    props: {
      showDelete: Boolean,
      disabled: Boolean
    }
  }
</script>
