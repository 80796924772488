<messages>["../BookEntry"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t('label.recharge')"
    :is-loading="isLoading"
    :cancel-action="cancelRecharge"
    @ok="ok">
    <v-row>
      <v-col cols="12">
        <i18n v-if="rechargeItem" :path="`label.rechargeConfirmation`">
          <template #amount>
            <span>{{ formatMoneyAmount (rechargeItem.amount, rechargeItem.currency) }}</span>
          </template>
          <template #client>
            <span>
              <client-link :id="rechargeItem.clientId"/>
              <template v-if="rechargeItem.clientId !== rechargeItem.subclientId">
                /
                <client-link :id="rechargeItem.subclientId"/>
              </template>
            </span>
          </template>
        </i18n>
      </v-col>
      <template v-if="!bundleDisabled">
        <v-col cols="12">
          <bundle-list :book-entry-data="bundle" :loading="isLoadingBundle"/>
        </v-col>
      </template>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import ClientLink from '@/app/core/components/ClientLink'

  import BundleList from './BundleList'

  export default {

    components: {
      ConfirmationDialog,
      ClientLink,
      BundleList
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      rechargeItem: {
        type: Object,
        default: null
      },

      cancelRecharge: {
        type: Function,
        default () {
          this.showDialog = false
        }
      }
    },

    data () {
      return {
        bundleDisabled: false,
        bundle: [],
        isLoadingBundle: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    watch: {
      showDialog (newVal) {
        if (newVal) {
          this.bundleDisabled = !this.rechargeItem.bundleId
          if (!this.bundleDisabled) this.loadBundle ()
        }
      },
      'rechargeItem.bundleId' () {
        if (this.rechargeItem.bundleId) {
          this.loadBundle ()
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ok () {
        this.$emit ('ok')
      },

      loadBundle () {
        this.isLoadingBundle = true

        this.fetchData ({
          op: 'account/list-bundled-bookentries',
          params: {
            id: this.rechargeItem.bundleId
          },
          cb: (data) => {
            this.bundle = data.list
          },
          cbFinal: () => {
            this.isLoadingBundle = false
          }
        })
      }
    }
  }
</script>
