<messages>["../BookEntry"]</messages>

<template>
  <v-menu open-on-hover>
    <template #activator="{ on }">
      <span v-on="on">
        {{ exchange.appliedRate }}
        <v-icon v-if="!hideInfo" small>info</v-icon>
      </span>
    </template>
    <v-card>
      <v-card-text>
        <v-row justify="center" align="center">
          <span class="grey--text text--darken-1">
            {{ $t ('label.exchangeRate') }}: {{ formatMoneyAmount (1, originalCurrency) }} = {{
              formatMoneyAmount (exchange.appliedRate, destinationCurrency, $i18n.locale, '10') }}
          </span>
          <v-col cols="12" class="px-4">
            <v-row v-if="exchange.originalGrossAmount" justify="space-between" align-content="center">
              <v-col cols="12">
                {{ $t ('label.grossConversion') }}:
              </v-col>
              <v-col cols="5" align-self="center">
                {{ exchange.originalGrossAmount ? formatMoneyAmount (exchange.originalGrossAmount.value, exchange.originalGrossAmount.currency) : '-' }}
              </v-col>

              <v-col cols="2" align-self="center" class="text-center">
                <v-icon>
                  trending_flat
                </v-icon>
              </v-col>

              <v-col cols="5" align-self="center" class="text-right">
                {{ formatMoneyAmount (exchange.convertedGrossAmount.value, exchange.convertedGrossAmount.currency) }}
              </v-col>
            </v-row>
            <v-row v-if="exchange.originalNetAmount" justify="space-between" align-content="center">
              <v-col cols="12">
                {{ $t ('label.netConversion') }}:
              </v-col>
              <v-col cols="5" align-self="center">
                {{ exchange.originalNetAmount ? formatMoneyAmount (exchange.originalNetAmount.value, exchange.originalNetAmount.currency) : '-' }}
              </v-col>

              <v-col cols="2" align-self="center" class="text-center">
                <v-icon>
                  trending_flat
                </v-icon>
              </v-col>

              <v-col cols="5" align-self="center" class="text-right">
                {{ formatMoneyAmount (exchange.convertedNetAmount.value, exchange.convertedNetAmount.currency) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    props: {
      exchange: {
        type: Object,
        required: true
      },
      hideInfo: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      originalCurrency () {
        return this.exchange?.originalNetAmount?.currency ||
          this.exchange?.originalGrossAmount?.currency
      },

      destinationCurrency () {
        return this.exchange?.convertedNetAmount?.currency || this.exchange?.convertedGrossAmount?.currency
      }
    }
  }
</script>
