<messages>["../BookEntry"]</messages>

<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        ref="input"
        :value="selectedActions"
        :search-input.sync="searchTerm"
        multiple
        clearable
        auto-select-first
        :items="actionItems"
        :label="$t ('label.actions')"
        @input="onInput">
        <template
          #selection="{ item, parent }">
          <v-chip
            :key="item.value"
            close
            outlined
            small
            color="black"
            class="my-1"
            @update:active="parent.selectItem (item)">
            <span
              class="chipContent"
              :title="item.text"
              v-text="item.text"/>
          </v-chip>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col
          v-for="btn in actionsQuickFilter"
          :key="btn.label"
          cols="auto">
          <v-btn
            v-t="btn.label"
            rounded small
            @click="btn.method"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import {mapGetters} from 'vuex'

  export const actions = [
    'Create',
    'RenewManual',
    'RenewAuto',
    'TransferIn',
    'RefundDelete',
    'RefundTransfer',
    'RefundRenew',
    'Modify',
    'RefundCreateFailure',
    'Restore',
    'Refund',
    'Proxy',
    'RefundProxy',
    'Bid',
    'RefundBid',
    'PaymentAdvertising',
    'NSUsage',
    'RefundNSUsage',
    'Deposit',
    'RegistryLock',
    'RegistryUnlock',
    'Membership',
    'Generic',
    'MoneyExchange'
  ]

  const adminActions = [
    'Escrow',
    'RegistrarInsurance',
    'WhoisOperation',
    'FrontendSupport',
    'IcannFees'
  ]

  const refundActions = [
    'RefundDelete',
    'RefundTransfer',
    'RefundRenew',
    'RefundCreateFailure',
    'Refund',
    'RefundProxy',
    'RefundBid',
    'RefundNSUsage'
  ]

  const objectActions = [
    'Create',
    'Modify',
    'Restore',
    'RenewManual',
    'RenewAuto',
    'TransferIn'
  ]

  export default {
    props: {
      value: {
        type: Array,
        default: undefined
      }
    },

    data () {
      return {
        actionsQuickFilter: [
          {
            label: 'label.refundActions',
            method: () => { this.selectedActions = [...refundActions] }
          },
          {
            label: 'label.objectActions',
            method: () => { this.selectedActions = [...objectActions] }
          }
        ],
        searchTerm: ''
      }
    },

    computed: {
      ...mapGetters ({
        permissions: 'auth/permissions'
      }),

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      selectedActions: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      actionItems () {
        return [
          ...actions,
          ...this.isAdmin ? adminActions : []
        ].map (it => ({
          text: this.$t (`actions.${it}`),
          value: it
        })).sort ((a, b) => a.text.localeCompare (b.text))
      }

    },

    methods: {
      focus () {
        this.$refs.input.focus ()
      },
      onInput (v) {
        this.selectedActions = v
        this.searchTerm = ''
      }
    }
  }
</script>
