<messages>["@/app/i18n/common/GenericObject", "../BookEntry"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="bookEntryData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #[`header.accountBalance`]="{header}">
      <span class="wrap-header">{{ header.text }}</span>
    </template>

    <template #item="props">
      <tr>
        <td>
          <memorandum-text-info :memorandum="props.item.memorandumText">
            <router-link
              v-t="`actions.${props.item.action}`"
              :to="detailsLink (props.item)"/>
          </memorandum-text-info>
        </td>
        <td
          :class="['amount', {'red--text': props.item.amount < 0}]"
          v-text="formatMoneyAmount (props.item.amount, props.item.currency)"/>
        <td>
          <router-link
            v-if="!!objectLink (props.item)"
            :to="objectLink (props.item)"
            v-text="props.item.objectName"/>
          <span
            v-else-if="!!props.item.objectName"
            v-text="props.item.objectName"/>
          <span v-else-if="!!props.item.memorandumText">
            {{ props.item.memorandumText }}
          </span>
          <span v-else>{{ emptyMark }}</span>
          <div>
            <client-link :id="props.item.clientId" short/>
            <template v-if="props.item.clientId !== props.item.subclientId">
              →
              <client-link :id="props.item.subclientId" short/>
            </template>
          </div>
        </td>
        <td>
          <span v-if="!!props.item.years">{{ props.item.years }}</span>
          <span v-else>{{ emptyMark }}</span>
        </td>
        <!-- <td>
          <client-link :id="props.item.clientId"/>
          <template v-if="props.item.clientId !== props.item.subclientId">
            /
            <client-link :id="props.item.subclientId"/>
          </template>
        </td> -->
        <td v-text="formatDate (props.item.creationDate)"/>
        <td v-text="props.item.id"/>
        <td :class="{'red--text': props.item.overallAccountBalance < 0, 'text-right': true}">
          <balances-info
            :balance-amount="props.item.overallAccountBalance"
            :balance-currency="props.item.overallAccountBalanceCurrency"
            :inaccurate="referenceDate > props.item.creationDate"
            hide-menu
            :balances="props.item.balances"/>
        </td>
        <td>
          <conversion-info v-if="!!props.item.exchangeRate" :exchange="props.item.exchangeRate" hide-info/>
          <span v-else>{{ emptyMark }}</span>
        </td>
        <td>
          <vat-info v-if="!!props.item.taxes" :vats="props.item.taxes" :currency="props.item.currency" hide-info/>
          <span v-else>{{ emptyMark }}</span>
        </td>
        <td>
          <!--suppress JSCheckFunctionSignatures -->
          <action-buttons
            :value="isActionButtonsActive (props.item.id)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.id) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {EmptyMark} from '@/app/utils/string'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import ConversionInfo from './ConversionInfo'
  import VatInfo from './VatInfo'
  import MemorandumTextInfo from './MemorandumTextInfo'
  import {invalidReferenceDate} from '../constants'

  import {mapGetters} from 'vuex'

  import ClientLink from '@/app/core/components/ClientLink'
  import ActionButtons from '@/app/core/components/ActionButtons'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'
  import BalancesInfo from '@/app/pages/Account/components/BalancesInfo'

  export default {
    name: 'BookEntryTable',

    components: {
      BalancesInfo,
      ActionButtons,
      SearchTableNoData,
      ConversionInfo,
      VatInfo,
      MemorandumTextInfo,
      ClientLink
    },

    mixins: [paginationMixins, actionButtonsHelper, registryCommands],

    props: {
      totalCount: {
        type: Number,
        default: 0
      },
      bookEntryData: {
        type: Array,
        default: () => []
      },
      rowsPerPageItems: {
        type: Array,
        required: true
      },
      loading: Boolean
    },

    data () {
      return {
        referenceDate: invalidReferenceDate
      }
    },

    computed: {
      ...mapGetters ({
        getBookEntryNameFilter: 'filter/getDomainSearchFilterWithBookEntryName',
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions'
      }),

      headers () {
        const headerItem = it => ({
          text: this.$t (`label.${it}`),
          value: it
        })

        const amount = headerItem ('amount')
        amount.align = 'right'

        return [
          headerItem ('action'),
          amount,
          {...headerItem ('objectNameInfo'), sortable: false},
          {
            text: this.$t ('label.years'),
            sortable: false
          },
          headerItem ('creationDate'),
          {
            text: this.$t ('client.header.id'),
            sortable: false
          },
          {
            text: this.$t ('label.accountBalance'),
            name: 'accountBalance',
            value: 'overallAccountBalance',
            sortable: false,
            align: 'right'
          },
          {
            text: this.$t ('label.exchangeRate'),
            sortable: false
          },
          {
            text: this.$t ('label.vat'),
            sortable: false
          },
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      emptyMark () {
        return EmptyMark
      }
    },

    methods: {
      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        return [
          {
            action: 'view',
            id: item.id,
            icon: 'visibility',
            disabled: false,
            tooltip: this.$t ('general.button.view')
          },
          ...this.isAdmin
            ? [
              {
                action: 'refund',
                item: item,
                icon: 'undo',
                disabled: !item.refundable,
                tooltip: this.$t ('general.button.refund')
              }
            ]
            : [],
          ...this.isAdmin && !item.refundable && item.amount < 0
            ? [
              {
                action: 'chargeAgain',
                item: item,
                icon: 'redo',
                tooltip: this.$t ('label.chargeAgain')
              }
            ]
            : []
        ]
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        const params = (({id}) => ({id})) (button)

        switch (button.action) {
          case 'view':
            this.$router.push (
              {
                name: 'bookentry.view',
                params
              }
            )
            break

          case 'refund':
            this.$emit ('refund', button.item)
            break
          case 'chargeAgain':
            this.$emit ('chargeAgain', button.item)
            break
        }
      },

      /**
       * Generate the link data for the book entry details view.
       *
       * @param {Object} bookEntry     the book entry to create the link for
       */
      detailsLink (bookEntry) {
        return {
          name: 'bookentry.view',
          params: {id: bookEntry.id}
        }
      },

      /**
       * Generate the link data for the book entry related object detail view.
       *
       * @param {Object} bookEntry     the book entry to create the link for
       */
      objectLink ({objectName, objectType, objectId}) {
        let to

        if (objectName && objectId) {
          let name

          switch (objectType) {
            case 'Domain':
            case 'Contact':
            case 'Host':
              name = `${objectType.toLowerCase ()}.view.id`
              break
            default:
              return undefined
          }

          if (name) {
            to = {name, params: {id: objectId}}
          }
        }

        return to
      },

      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      }
    }
  }
</script>>

<style scoped>
.amount {
  text-align: right;
}

.wrap-header {
  white-space: normal;
}
</style>
