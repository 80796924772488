<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-badge
        color="warning"
        dot>
        <slot name="activator" :on="on"/>
      </v-badge>
    </template>
    <span>
      {{ tooltip }}
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      tooltip: {
        type: String,
        required: true
      }
    }
  }
</script>
