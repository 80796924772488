<messages>["../BookEntry"]</messages>

<template>
  <v-row class="mt-3">
    <v-col cols="12" sm="3">
      <badge-tooltip
        :tooltip="$t ('label.inaccurateBalance')">
        <template #activator="{ on }">
          <span
            v-on="on"
            v-text="$t ('label.accountBalance')"/>
        </template>
      </badge-tooltip>
    </v-col>
    <v-col cols="12" sm="1">
      =
    </v-col>
    <v-col cols="12" sm="8">
      {{ $t ('label.invalidBalance') }}
    </v-col>
  </v-row>
</template>

<script>
  import BadgeTooltip from '@/app/core/components/BadgeTooltip'

  export default {
    components: {BadgeTooltip}
  }
</script>
