<messages>["@/app/i18n/common/GenericObject", "../BookEntry"]</messages>

<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="bookEntry"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <!-- filter form -->

    <template #basic>
      <v-row>
        <v-col
          class="pa-1"
          cols="12" sm="6">
          <v-text-field
            ref="objectName"
            v-model.trim="filterParams.objectName"
            name="objectName"
            :label="$t (`label.objectName`)"
            clearable/>
        </v-col>
        <v-col
          class="pa-1"
          cols="12" sm="6">
          <v-checkbox
            ref="objectNamePartialMatch"
            v-model="filterParams.objectNamePartialMatch"
            :label="$t ('label.objectNamePartialMatch')"/>
        </v-col>
        <v-col
          class="pa-1"
          cols="12">
          <registry-select
            ref="registryTypeNames"
            v-model="filterParams.registryTypeNames"
            include-inactive
            multiple
            clearable
            :label="$t('label.registryTypes')"
            :hint="$t ('label.registryTypesHint')"/>
        </v-col>
      </v-row>
      <v-row v-if="mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients)" align="center">
        <v-col
          class="pa-1"
          cols="12" sm="6">
          <client-select
            ref="clientId"
            v-model="filterParams.clientId"
            show-inactive
            for-view
            nullable/>
        </v-col>
        <v-col
          class="pa-1"
          cols="12" sm="6">
          <v-checkbox
            v-model="filterParams.excludeSubclients"
            class="additionalCheckbox"
            :disabled="!filterParams.clientId"
            :label="$t (`label.excludeSubclients`)"/>
        </v-col>
        <v-col v-if="mayViewAllObjects" class="pa-1" cols="12">
          <v-select
            ref="payer"
            v-model="filterParams.payer"
            :label="$t ('label.payer')"
            :items="payerOptions"/>
        </v-col>
      </v-row>
    </template>
    <template #additional>
      <v-row>
        <v-col>
          <filter-date-range
            v-model="filterParams.creationDateRange"
            :label="$t (`label.creationDate`)"/>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="auto">
          <v-btn icon small @click="addDateFilter">
            <v-icon small>
              add
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-for="btn in buttons"
          :key="btn.label"
          cols="auto">
          <v-btn
            v-t="btn.label"
            rounded small
            @click="btn.method"/>
        </v-col>
      </v-row>
      <action-select
        ref="action"
        v-model="filterParams.action"/>
    </template>
  </search-filter-wrapper>
</template>

<script>
  import {mapGetters} from 'vuex'

  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'
  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'

  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import ClientSelect from '@/app/core/components/ClientSelect'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  import FilterDateRange, {
    defaultValue as dateRangeDefaultValue,
    lastMonthItems as lastMonthFilter,
    currentMonthItems as currentMonthFilter,
    lastYearItems as lastYearFilter,
    currentYearItems as currentYearFilter
  } from '@/app/core/components/Search/FilterDateRange'

  import {defaultValue as defaultDateItem}
    from '@/app/core/components/Search/FilterDateRangeItem'

  import ActionSelect from './ActionSelect'

  const CLIENT_ONLY = null
  const REGISTRAR_ONLY = true
  const ANY = false

  const names = {
    [CLIENT_ONLY]: 'filter.payerOptions.client',
    [REGISTRAR_ONLY]: 'filter.payerOptions.registrar',
    [ANY]: 'filter.payerOptions.any'
  }

  /**
   * default bookEntry search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    objectName: null,
    registryTypeNames: [],
    clientId: null,
    excludeSubclients: false,
    creationDateRange: dateRangeDefaultValue (),
    valutaDateRange: dateRangeDefaultValue (),
    action: [],
    objectNamePartialMatch: false,
    payer: CLIENT_ONLY
  })

  export default {
    name: 'BookEntryFilter',

    components: {
      ClientSelect,
      FilterDateRange,
      RegistrySelect,
      SearchFilterWrapper,
      ActionSelect
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      QuickFilterMixin,
      InputHelper
    ],

    data () {
      return {
        additionalFilterKeys: [
          'creationDateRange',
          'valutaDateRange',
          'action'
        ],
        customHandlerKeys: [
          'creationDateRange',
          'valutaDateRange',
          'excludeSubclients',
          'action',
          'objectNamePartialMatch',
          'payer'
        ],
        buttons: [
          {
            label: 'label.currentMonth',
            method: this.onlyCurrentMonth
          },
          {
            label: 'label.lastMonth',
            method: this.onlyLastMonth
          },
          {
            label: 'label.currentYear',
            method: this.onlyCurrentYear
          },
          {
            label: 'label.lastYear',
            method: this.onlyLastYear
          }
        ]
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayViewAllObjects',
        'mayViewSubEntitiesObjects',
        'hasSubClients'
      ]),

      payerOptions () {
        return [CLIENT_ONLY, REGISTRAR_ONLY, ANY].map (o => ({
          text: this.$t (names[o]),
          value: o
        }))
      }
    },

    watch: {
      'filterParams.clientId' (newValue) {
        if (!newValue) {
          this.$set (this.filterParams, 'excludeSubclients', false)
        }
      }
    },

    methods: {
      customQuickFilterHandler (key, value, qFItems) {
        if (!value && key !== 'payer') {
          return
        }

        const dateRangeText = val => {
          const texts = []
          for (const dateRange of val) {
            if (dateRange?.timeRel && dateRange?.date) {
              texts.push (this.$t ('filter.dateRangeItem', dateRange))
            }
          }
          return texts.join (` ${this.$t ('filter.concat')} `)
        }

        let text = value
        let label

        switch (key) {
          case 'creationDateRange':
            label = this.$t ('label.creationDate')
            text = dateRangeText (value)
            break

          case 'valutaDateRange':
            label = this.$t ('label.date')
            text = dateRangeText (value)
            break

          case 'excludeSubclients':
          case 'objectNamePartialMatch':
            label = this.$t (`label.${key}`)
            text = ' '
            break

          case 'action':
            value.forEach ((val, idx) => {
              qFItems.push ({
                prop: 'action',
                label: this.$t ('label.action'),
                text: this.$t (`actions.${val}`),
                idx
              })
            })
            return
          case 'payer':
            label = this.$t (`label.${key}`)
            text = this.$t (names[value])
            break
        }

        label = label || this.$t (`label.${key}`)

        qFItems.push ({
          prop: key,
          text,
          label
        })
      },

      addDateFilter () {
        this.filterParams.creationDateRange.push (defaultDateItem ())
      },

      onlyLastMonth () {
        this.filterParams.creationDateRange = lastMonthFilter ()
      },

      onlyCurrentMonth () {
        this.filterParams.creationDateRange = currentMonthFilter ()
      },

      onlyLastYear () {
        this.filterParams.creationDateRange = lastYearFilter ()
      },

      onlyCurrentYear () {
        this.filterParams.creationDateRange = currentYearFilter ()
      }
    }
  }
</script>

<style scoped>
.additionalCheckbox {
  padding-top: 18px;
}
</style>
