export default {
  data () {
    return {
      activeActionButtonsId: 0
    }
  },

  computed: {
    isActionButtonsActive () {
      return id => this.activeActionButtonsId === id
    },

    setActionButtonsActive () {
      const activeActionButtonsChanged = this.activeActionButtonsChanged
      return function (id) {
        return function (state) {
          activeActionButtonsChanged (state, id)
        }
      }
    }
  },

  methods: {
    activeActionButtonsChanged (state, id) {
      this.activeActionButtonsId = state
        ? id
        : (
          this.activeActionButtonsId === id
            ? 0
            : this.activeActionButtonsId
        )
    },

    // dummy implementation (should be overridden in component)
    processActionButton (button) {
      console.warn ('Button processing is not implemented yet.\n' +
        'Button clicked:', button)
    }
  }
}
