<messages>["@/app/i18n/common/GenericObject", "../BookEntry"]</messages>

<template>
  <v-data-table
    class="elevation-1"
    disable-filtering
    hide-default-footer
    disable-pagination
    disable-sort
    :headers="headers"
    :items="bookEntryData"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            v-t="`actions.${props.item.action}`"
            :to="detailsLink (props.item)"/>
        </td>
        <td
          :class="['amount', {'red--text': props.item.amount < 0}]"
          v-text="formatMoneyAmount (props.item.amount, props.item.currency)"/>
        <td>
          <router-link
            v-if="!!objectLink (props.item)"
            :to="objectLink (props.item)"
            v-text="props.item.objectName"/>
          <span
            v-else-if="!!props.item.objectName"
            v-text="props.item.objectName"/>
          <span v-else-if="!!props.item.memorandumText">
            {{ props.item.memorandumText }}
          </span>
          <span v-else>{{ emptyMark }}</span>
        </td>
        <td v-if="mayManageAllEntities">
          <client-link v-if="props.item.payerType === 'Client'" :id="props.item.payerId"/>
          <registrar-link v-if="props.item.payerType === 'Registrar'" :id="props.item.payerId"/>
        </td>
        <td v-text="formatDate (props.item.creationDate)"/>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<script>
  import {EmptyMark} from '@/app/utils/string'

  import {invalidReferenceDate} from '../constants'

  import {mapGetters} from 'vuex'

  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'
  import ClientLink from '@/app/core/components/ClientLink'
  import RegistrarLink from '@/app/core/components/RegistrarLink'

  export default {
    name: 'BookEntryTable',

    components: {
      RegistrarLink,
      ClientLink,
      SearchTableNoData
    },
    props: {
      bookEntryData: {
        type: Array,
        default: () => []
      },
      loading: Boolean
    },

    data () {
      return {
        referenceDate: invalidReferenceDate,
        emptyMark: EmptyMark
      }
    },

    computed: {
      ...mapGetters ({
        getBookEntryNameFilter: 'filter/getDomainSearchFilterWithBookEntryName',
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions',
        mayManageAllEntities: 'auth/mayManageAllEntities'
      }),

      headers () {
        const headerItem = it => ({
          text: this.$t (`label.${it}`),
          value: it
        })

        const amount = headerItem ('amount')
        amount.align = 'right'

        return [
          headerItem ('action'),
          amount,
          {...headerItem ('objectNameInfo'), sortable: false},
          ...this.mayManageAllEntities ? [headerItem ('payer')] : [],
          headerItem ('creationDate')
        ]
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      }
    },

    methods: {

      /**
       * Generate the link data for the book entry details view.
       *
       * @param {Object} bookEntry     the book entry to create the link for
       */
      detailsLink (bookEntry) {
        return {
          name: 'bookentry.view',
          params: {id: bookEntry.id}
        }
      },

      /**
       * Generate the link data for the book entry related object detail view.
       *
       * @param {Object} bookEntry     the book entry to create the link for
       */
      objectLink ({objectName, objectType, objectId}) {
        let to

        if (objectName && objectId) {
          let name

          switch (objectType) {
            case 'Domain':
            case 'Contact':
            case 'Host':
              name = `${objectType.toLowerCase ()}.view.id`
              break
            default:
              return undefined
          }

          if (name) {
            to = {name, params: {id: objectId}}
          }
        }

        return to
      }
    }
  }
</script>>

<style scoped>
.amount {
  text-align: right;
}
</style>
