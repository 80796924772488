import _isEqual from 'lodash/isEqual'
import _merge from 'lodash/merge'
import _omitBy from 'lodash/omitBy'

export default function (defaultValue) {
  return {
    props: {
      visible: Boolean,
      searchDisabled: Boolean,
      value: {
        type: Object,
        default: () => defaultValue ()
      }
    },

    data () {
      return {
        filterParams: defaultValue ()
      }
    },

    computed: {
      emptyFilter () {
        return defaultValue ()
      },

      appliedFilter () {
        return this.value
      },

      filterVisibilityModel: {
        get () {
          return this.$vuetify.breakpoint.xlOnly || this.visible
        },

        set (newValue) {
          this.$emit ('visibilityChanged', newValue)
        }
      }
    },

    watch: {
      value () {
        this.populateFilterParams ()
      }
    },

    created () {
      this.populateFilterParams ()
    },

    methods: {
      populateFilterParams () {
        this.filterParams = _merge (defaultValue (), this.value)
      },

      // --- methods invoked from template -------------------------------------

      /**
       * Reset the search filter parameters.
       */
      resetSearchFilter () {
        this.filterParams = defaultValue ()
        this.$emit ('input', null)
      },

      /**
       * Actually perform the filtering, i.e., load the bookEntries that satisfy
       * the current filter settings.
       */
      doSearch () {
        // close the filter before search
        if (!this.$v?.$invalid) {
          this.filterVisibilityModel = false
          this.$emit ('input', this.normalizeFilter (this.filterParams))
        } else {
          this.$v.$touch ()
        }
      },

      /**
       * Writes the filter to the route as a string and stores it in the store
       *
       * @param {*} filter filter to be applied
       */
      normalizeFilter (filter) {
        const emptyFilter = defaultValue ()
        const fullFilter = _merge (defaultValue (), filter)

        return _omitBy (
          fullFilter, (v, k) => _isEqual (v, emptyFilter[k])
        )
      },

      /**
       * Get the empty filter params for the URL filter vlaue
       */
      getEmptyFilterParams () {
        return defaultValue ()
      }
    }
  }
}
