<template>
  <router-link
    v-if="mayViewRegistrar"
    :to="{name: 'registrar.view', params: {id}}"
    v-text="displayName || id"/>
  <span v-else>
    {{ displayName || id }}
  </span>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'RegistrarLink',

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        registrar: undefined
      }
    },

    computed: {
      ...mapGetters ({
        mayViewRegistrar: 'auth/mayManageAllEntities'
      }),

      displayName () {
        return this.registrar?.displayName
      }
    },

    watch: {
      id (newValue) {
        this.populateRegistrar (newValue)
      }
    },

    created () {
      this.populateRegistrar (this.id)
    },

    methods: {
      ...mapActions ({
        getRegistrar: 'cache/getRegistrar'
      }),

      async populateRegistrar (id) {
        this.registrar = await this.getRegistrar (id)
      }
    }
  }
</script>
