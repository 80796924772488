
<messages>["../BookEntry"]</messages>

<template>
  <v-menu open-on-hover>
    <template #activator="{on}">
      <badge-tooltip v-if="inaccurate" :tooltip="$t('label.inaccurateBalance')">
        <template #activator="{on: onTooltip}">
          <span :class="balanceClasses" v-on="onTooltip">
            {{ overallBalance }}
          </span>
        </template>
      </badge-tooltip>
      <span v-else :class="balanceClasses">
        {{ overallBalance }}
      </span>
      <v-icon v-if="balancesArray.length && !hideMenu" small v-on="on">
        info
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        <table>
          <tr>
            <th v-t="'label.balancesAfter'"/>
          </tr>
          <tr v-for="b in balancesArray" :key="b.currency">
            <td class="text-right">
              {{ formatMoneyAmount (b.amount, b.currency) }}
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  import BadgeTooltip from '@/app/core/components/BadgeTooltip'
  import {EmptyMark} from '@/app/utils/string'

  export default {
    components: {
      BadgeTooltip
    },
    props: {
      balanceAmount: {
        type: [String, Number],
        default: undefined
      },
      balanceCurrency: {
        type: [String],
        default: undefined
      },
      hideMenu: {
        type: Boolean,
        default: false
      },
      inaccurate: {
        type: Boolean,
        default: false
      },
      balances: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      balancesArray () {
        return Object.keys (this.balances).map ((key) => ({
          currency: key,
          amount: this.balances[key]
        }))
      },
      showMenu () {
        return this.balancesArray.length &&
          ((!this.overallAvailable && this.balancesArray.length > 1) || !this.hideMenu)
      },
      overallAvailable () {
        return this.balanceAmount !== undefined && this.balanceAmount !== null
      },
      balanceClasses () {
        return this.balanceAmount < 0 ? ['red--text'] : []
      },
      overallBalance () {
        return this.overallAvailable
          ? this.formatMoneyAmount (this.balanceAmount,
                                    this.balanceCurrency)
          : this.balancesArray.length === 1
            ? this.formatMoneyAmount (this.balancesArray[0].amount,
                                      this.balancesArray[0].currency)
            : EmptyMark
      }
    }
  }
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 2em 0;
}
</style>
