import {getPaginationForRequest} from './Pagination'

/**
 * default footer properties, which can be used for `VDataTable` component
 *
 * @returns Object      default footer properties
 */
export function footerProps () {
  return {
    'items-per-page-text': this.$t ('general.pagination.rowsPerPage'),
    'items-per-page-options': [10, 25, {text: this.$t ('general.label.all'), value: -1}]
  }
}

/**
 * get default "no data" text according to specified `loading` state for `VDataTable` component
 *
 * @param isLoading     the flag, indicating whether data are loading now
 * @param text          text to be used if not loading, if not specified, then default text will be used
 * @returns String      "no data" text according to specified `loading` state
 */
export function noDataText (isLoading, text) {
  return isLoading
    ? this.$t ('general.pagination.loading')
    : text || this.$t ('general.pagination.noDataText')
}

/**
 * get default "no result" text according to specified `loading` state for `VDataTable` component
 *
 * @param isLoading     the flag, indicating whether data are loading now
 * @param text          text to be used if not loading, if not specified, then default text will be used
 * @returns String      "no result" text according to specified `loading` state
 */
export function noResultsText (isLoading, text) {
  return isLoading
    ? this.$t ('general.info.searchInProgress')
    : text || this.$t ('general.pagination.noResultsText')
}

export default {
  props: {
    sortBy: String,
    descending: Boolean,
    page: {
      default: 1,
      type: Number
    },
    rowsPerPage: {
      default: -1,
      type: Number
    }
  },

  data () {
    return {
      pagination: {
        sortBy: this.sortBy,
        descending: this.descending,
        page: this.page,
        rowsPerPage: this.rowsPerPage
      }
    }
  },

  computed: {
    // default footer properties, which can be used for `VDataTable` component
    footerProps,

    externalPaginationState () {
      return {
        sortBy: this.sortBy,
        descending: this.descending,
        page: this.page,
        rowsPerPage: this.rowsPerPage
      }
    },

    internalPaginationState () {
      return {
        sortBy: this.pagination.sortBy,
        descending: this.pagination.descending,
        page: this.pagination.page,
        rowsPerPage: this.pagination.rowsPerPage
      }
    }
  },

  watch: {
    externalPaginationState: {
      handler () {
        this.pagination = this.externalPaginationState
      },
      deep: true
    },

    internalPaginationState: {
      /**
       * handler for internal pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      handler (newValue, oldValue) {
        this.onPaginationStateChanged (newValue, oldValue)
      },
      deep: true
    }
  },

  methods: {
    // default text properties, which can be used for `VDataTable` component
    noDataText,
    noResultsText,

    /**
     * extend the route  query by current pagination state
     *
     * @param newValue      new pagination state
     */
    putPaginationStateToRouteQuery (newValue) {
      this.$router.replace ({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...newValue
        }
      }).catch (() => {
        // this function is needed to ignore the `NavigationDuplicated` exception thrown by `vue-router`
        // the solution is taken from
        // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
      })
    },

    /**
     * function called on pagination state changes
     *
     * @param newValue      new pagination state
     * @param oldValue      old pagination state
     */
    onPaginationStateChanged (newValue, oldValue) {
      const {sortBy} = newValue

      // the Vuetify 2 supports data table sorting by multiple columns,
      // thus the `sortBy` option will be set to `Array` on clicking the
      // column header and we need to change it back to the string manually,
      // because the backend does not support multiple column sorting (yet?)
      if (Array.isArray (sortBy)) {
        newValue.sortBy = sortBy[0]
      }

      this.putPaginationStateToRouteQuery (newValue)

      this.list ()
    },

    list () {
      // empty implementation
    },

    /**
     * converts current Vuetify pagination object to object, which properties
     * are supported by common list requests
     *
     * @return {Object}         object, which properties are supported by
     *                          common list requests
     */
    getPaginationForRequest () {
      return getPaginationForRequest (this.pagination)
    }
  }
}
