<messages>["./Search"]</messages>

<template>
  <v-col>
    <v-toolbar
      class="elevation-1 front"
      height="44"
      flat
      color="white">
      <v-toolbar-title v-t="`label.filter.${type}`"/>
      <v-spacer/>
      <v-toolbar-items class="hidden-xl-only">
        <v-btn
          text
          color="primary"
          @click="toggleFilter">
          {{ !openFilter ? $t ('label.open') : $t ('label.close') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- TODO: call focus method after transition finished @after-enter="focus" -->
    <v-expand-transition mode="out-in">
      <v-card
        v-if="openFilter"
        key="filter"
        class="grey lighten-3">
        <form
          novalidate
          @submit.prevent="emitSearch">
          <v-card-text>
            <slot name="basic"/>

            <!-- TODO: call focus method after transition finished @after-enter="focus" -->
            <v-expand-transition>
              <v-card
                v-if="additional && this.$slots.additional"
                key="additional"
                class="my-3"
                flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pa-1">
                      <v-btn
                        v-t="'label.additional.close'"
                        class="mb-2"
                        @click="additional = !additional"/>
                    </v-col>
                  </v-row>

                  <slot name="additional"/>
                </v-card-text>
              </v-card>
            </v-expand-transition>
            <quick-filter
              v-if="!additional && this.$slots.additional"
              key="quick"
              ma-4
              :items="additionalQuickFilterItems"
              @clicked="focusAdditional"
              @delete="onDeleteAdditionalFilter"/>
            <!-- filter form buttons -->
            <v-row :class="{wrap: $vuetify.breakpoint.xsOnly, mt3:true}"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="!additional && this.$slots.additional"
              v-t="'label.additional.open'"
              @click="toggleAdditional"/>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'"
              text
              :disabled="searchDisabled"
              @click="onResetFilter"/>
            <v-btn
              v-t="'filter.button.search'"
              type="submit"
              color="primary"
              :disabled="searchDisabled"/>
          </v-card-actions>
        </form>
      </v-card>
      <v-card
        v-if="!openFilter && !isQuickFilterEmpty"
        key="quick">
        <v-card-text>
          <quick-filter
            ma-4
            :disabled="searchDisabled"
            :items="quickFilterItems"
            @clicked="onQuickFilterClick"
            @delete="onDeleteFilter"/>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-col>
</template>

<script>
  import QuickFilter from '@/app/core/components/Search/QuickFilter'

  // container attributes for filter input controls
  // used to encapsulate common layout definitions for different breakpoints

  // maximal 1 control in the row
  export const max1Attrs = {cols: 12, 'pa-1': true}

  // maximal 3 controls in the row
  export const max3Attrs = {cols: 12, sm: 4, xl: 12, 'pa-1': true}

  export default {
    name: 'SearchFilterWrapper',

    components: {
      QuickFilter
    },

    props: {
      value: Boolean,
      searchDisabled: Boolean,
      type: {
        type: String,
        required: true,
        validator (v) {
          return [
            'domain', 'application', 'host', 'contact', 'zone',
            'bookEntry', 'invoice', 'email', 'overallResults'
          ].includes (v)
        }
      },
      quickFilterItems: {
        type: Array,
        default: undefined
      },
      additionalQuickFilterItems: {
        type: Array,
        default: undefined
      }
    },

    data () {
      return {
        additional: false,
        focusProp: null
      }
    },

    computed: {
      isQuickFilterEmpty () {
        return !this.quickFilterItems?.length
      },
      openFilter: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      value (newVal) {
        if (!newVal) {
          this.additional = false
        }
      },

      type () {
        this.focusProp = ''
        this.openFilter = true
      }
    },

    methods: {
      onDeleteFilter (e) {
        this.$emit ('deleteFilter', e)
      },

      onDeleteAdditionalFilter (e) {
        this.$emit ('deleteAdditionalFilter', e)
      },

      onResetFilter (e) {
        this.$emit ('reset', e)
      },

      emitSearch (e) {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          this.$emit ('search', e)
        })
      },

      onQuickFilterClick (index) {
        this.$emit ('input', true)

        if (this.additionalQuickFilterItems.find (
          (item) => item.prop === this.quickFilterItems[index].prop)) {
          this.additional = true
        }

        this.focusProp = this.quickFilterItems[index].prop
        this.$nextTick (() => this.focus ())
      },

      focusAdditional (index) {
        this.focusProp = this.additionalQuickFilterItems[index].prop
        this.additional = true
        this.$nextTick (() => this.focus ())
      },

      focus () {
        this.$emit ('focus', this.focusProp)
      },

      toggleAdditional () {
        this.focusProp = ''
        this.additional = !this.additional
      },

      toggleFilter () {
        this.focusProp = ''
        this.openFilter = !this.openFilter
      }
    }
  }
</script>

<style scoped>
  .front {
    z-index: 1
  }
</style>
