/**
 * the string, used to indicate, that the value of some property is empty
 * (not set)
 *
 * @type {string}
 */
export const EmptyMark = '\u2014'

/**
 * creates the camelCaseString (prefixSuffix) from given prefix and suffix;
 * if prefix is empty, the suffix fist letter will be lower case, otherwise
 * if will be upper case
 *
 * @param prefix        string prefix
 * @param suffix        string suffix
 * @return {string}     camel cased string composed from prefix and suffix
 */
export function toCamelCase (prefix, suffix) {
  let firstLetter = suffix.charAt (0)

  firstLetter =
    prefix ? firstLetter.toUpperCase () : firstLetter.toLowerCase ()

  return prefix + firstLetter + suffix.slice (1)
}

/**
 * Remove all whitespace from the given string.
 *
 * @param {String} string       the string to handle
 * @return {String}             the string without whitespace
 */
export function removeWhitespace (string) {
  return string.replace (/\s/g, '')
}

/**
 * Remove a prefix from a given string.
 *
 * @param {String} string      the string
 * @param {String} prefix      the prefix to remove
 * @return {String}            the modified string (equals the original one if
 *                             the original string does not begin with the given
 *                             prefix)
 */
export function removePrefix (string, prefix) {
  return string.startsWith (prefix) ? string.substr (prefix.length) : string
}
