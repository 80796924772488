/**
 * This mixin is supposed to be used on "search pages" to handle common
 * functionality
 */

import _isEqual from 'lodash/isEqual'

import {mapGetters, mapActions} from 'vuex'

import Pagination, {
  calculateRowsPerPageLimit,
  getPaginationForRequest
} from './Pagination'

export default {
  mixins: [Pagination],

  props: {
    /** search filter */
    filter: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      // static object part to be passed to `RegistryObjectDeleteDialog`
      // component (need to be re-defined)
      DELETE_OBJECT_STATIC_PROPS: {
        url: 'PATH/TO/DELETE-ACTION',
        objectType: 'TYPE-OF-OBJECT' // 'domain', 'contact', 'host', etc.
      },
      isLoading: false,
      resultList: [],
      showFilter: true,
      totalCount: 0,
      // this value corresponds to `LIMITABLE_MAX_COUNT` from backend delegates
      // and can be overridden inside each component, using this mixin
      rowsPerPageLimit: 100,
      isResultTableVisible: false,
      isDeleteDialogVisible: false,
      deleteObject: null
    }
  },

  computed: {
    ...mapGetters ({
      operationLink: 'request/operationLink'
    }),

    // this value is used by mixin for sending requests to the BE API
    // and need to be re-defined
    // disabled so the components can either use computed or data prop
    // OPERATION () {
    //   return 'PATH/TO/SEARCH-ACTION'
    // },

    csvDownloadUrl () {
      // noinspection JSUnusedLocalSymbols
      const {page, size, ...sorting} =
        getPaginationForRequest (this.paginationState)

      return this.operationLink ({
        op: this.OPERATION,
        params: {
          filter: this.filter,
          ...this.disablePagination
            ? {}
            : sorting
        }
      })
    },

    showResultAlert () {
      return this.$vuetify.breakpoint.xlOnly && !this.isResultTableVisible
    },

    /**
     * construct search parameters object, which can be used as route query
     *
     * @return {Object}     search parameters object, which can be used as
     *                      route query
     */
    searchParams () {
      return this.createSearchQuery (this.filter, this.paginationState)
    }
  },

  watch: {
    searchParams: {
      handler (newValue, oldValue) {
        if (!_isEqual (newValue, oldValue)) {
          if (!_isEqual (newValue.filter, oldValue.filter)) {
            this.paginationState.page = 1
            this.updateProps ({filter: this.filter, pagination: this.paginationState})
          }
          this.applyFilter ()
        }
      },
      deep: true
    },

    /**
     * handle changes on result list
     */
    resultList () {
      // the `rowsPerPageLimit` should be corrected if get less results as
      // requested
      const limit = calculateRowsPerPageLimit (
        this.resultList.length, this.totalCount, this.paginationState)

      if (limit !== this.rowsPerPage) {
        this.rowsPerPageLimit = limit

        const params = {
          filter: this.filter,
          pagination: {
            ...this.paginationState,
            rowsPerPage: limit
          }
        }

        this.storeSearchQuery (params)
        this.updateProps (params)
      }
    }
  },

  created () {
    if (this.filter) {
      this.applyFilter ()
    } else {
      this.updateProps ({
        filter: null,
        pagination: {},
        ...this.getStoredSearchQuery ()
      })
    }
  },

  methods: {
    // --- actions, mapped from vuex -------------------------------------------
    ...mapActions ({
      fetchData: 'request/fetchData'
    }),

    /**
     * create the object, representing the current search state
     * (filter and pagination)
     *
     * @param filter                current search filter
     * @param paginationState       current pagination state
     * @return {Object}             combination of current search filter and
     *                              pagination
     */
    createSearchQuery (filter, paginationState) {
      return {
        ...{filter: filter ? JSON.stringify (filter) : undefined},
        ...this.disablePagination ? {} : paginationState
      }
    },

    /**
     * change the filter visibility according to specified parameter
     *
     * @param newValue      new filter visibility state
     */
    onFilterVisibilityChange (newValue) {
      this.showFilter = newValue
    },

    /**
     * handle changes on filter
     *
     * @param filter      changed filter
     */
    onFilterChange (filter) {
      const params = {
        filter,
        pagination: filter ? this.paginationState : null
      }
      this.storeSearchQuery (params)
      this.updateProps (params)
    },

    /**
     * Handle pagination state changes
     *
     * @param newValue      new pagination state
     * @param oldValue      old pagination state
     */
    onPaginationStateChanged (newValue, oldValue = this.paginationState) {
      const {sortBy} = newValue

      // the Vuetify 2 supports data table sorting by multiple columns,
      // thus the `sortBy` option will be set to `Array` on clicking the
      // column header and we need to change it back to the string manually,
      // because the backend does not support multiple column sorting (yet?)
      if (Array.isArray (sortBy)) {
        newValue.sortBy = sortBy[0]
      }

      const params = {filter: this.filter, pagination: newValue}
      this.storeSearchQuery (params)
      this.updateProps (params)
    },

    /**
     * update component properties by changing the current route query
     * parameters
     *
     * @param filter        current search filter
     * @param pagination    current pagination state
     */
    updateProps ({filter, pagination}) {
      this.$router.replace ({
        name: this.$route.name,
        query: this.createSearchQuery (filter, pagination)
      }).catch (() => {
        // this function is needed to ignore the `NavigationDuplicated` exception thrown by `vue-router`
        // the solution is taken from
        // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
      })
    },

    /**
     * store given search filter, so that it can be used later e.g. with the aid
     * of Vuex store
     *
     * @param query     query to be stored
     */
    storeSearchQuery (query) {
      throw new Error (
        `The \`storeSearchQuery\` was called with the parameter "${query}", but this method is not implemented`)
    },

    /**
     * get the stored search filter, which will be used on component creation
     */
    getStoredSearchQuery () {
      throw new Error ('The `getStoredSearchQuery` is not implemented')
    },

    /**
     * Actually perform the filtering, i.e., load the objects that satisfy the
     * current filter settings.
     */
    applyFilter () {
      if (this.filter) {
        this.load ()
      } else {
        this.isResultTableVisible = false
      }
    },

    /**
     * open object deletion confirmation dialog
     *
     * @param objToDelete   object to be deleted
     */
    openConfirmDeleteDialog (objToDelete) {
      this.deleteObject = {
        ...this.DELETE_OBJECT_STATIC_PROPS,
        ...objToDelete
      }
      this.isDeleteDialogVisible = true
    },

    /**
     * Load objects, matching the specified filter from the backend.
     */
    load () {
      this.showFilter = false
      this.isResultTableVisible = true
      this.isLoading = true
      this.resultList = []

      this.fetchData ({
        op: this.OPERATION,
        params: {
          ...this.disablePagination
            ? {}
            : getPaginationForRequest (this.paginationState),
          filter: this.filter
        },
        cb: data => {
          this.resultList = data.list
          this.totalCount = data.totalCount
        },
        cbFinal: () => {
          this.isLoading = false
        }
      })
    }
  }
}
